import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
//import { importAll } from "../utility/utilities"
import ReactPaginate from 'react-paginate';
import filterIcon from "../assets/img/filter-icon.svg"
import distribution from "../assets/metadata/distribution.json"
import allMetadata from "../assets/metadata/all.json"

const dist: any = distribution
const allMeta: AllMetadata[] = allMetadata
//const imageList: Images = importAll(require.context('../../public//8liens', false, /\.(png|jpe?g|svg)$/ ))
const itemsPerPage = 12

export const FilterSection = () => {
    const [hiddenStatus, setHiddenStatus] = useState<boolean[]>([])
    const [traitChecked, setTraitChecked] = useState<CheckedObj>({})
    const [images, setImages] = useState<Images>({})
    const [filters, setFilters] = useState<string[]>([])
    const [pageCount, setPageCount] = useState(0)
    const [itemOffset, setItemOffset] = useState(0)
    
    let navigate = useNavigate();

    useEffect(() => {
        const getHiddenStatus = () => {
            let getHiddenStatus: boolean[] = []
            let checkedObj: CheckedObj = {}

            Object.keys(dist).forEach((key, index) => {
                getHiddenStatus.push(true)
                Object.keys(dist[key].traits).forEach((distKey, distIndex) => {
                  checkedObj[distKey] = false
                })
            })
            setHiddenStatus(getHiddenStatus)
            //console.log(hiddenStatus)
            setTraitChecked(checkedObj)
            //console.log("Checked:", checkedObj)
        }
        getHiddenStatus()
    }, [])


    const toggleHide = (index: number) => {
        let updateArray = [...hiddenStatus]
        updateArray[index] = !hiddenStatus[index]
        setHiddenStatus(updateArray)
    }

    const handleCheckboxChange = (filterKey: string) => {
      setItemOffset(0)  
      const index = filters.indexOf(filterKey)
        let updateFilters = [...filters]
        if (index > -1) {
            updateFilters.splice(index, 1)
        } else {
            updateFilters.push(filterKey)
        }

        setFilters(updateFilters)
        let updateCheckedObj = {...traitChecked}
        updateCheckedObj[filterKey] = !updateCheckedObj[filterKey]
        setTraitChecked(updateCheckedObj)
        //console.log(updateFilters)
    }

    const clearFilters = () => {
      setFilters([])
      setTraitChecked({})
      setItemOffset(0)
    }

    const handlePageClick = (event: any) => {
      const newOffset = (event.selected * itemsPerPage) % Object.keys(images).length
      setItemOffset(newOffset)
    }

    const addImageToList = (index: number) => {
      let image = ''
      const animationUrl = allMeta[index].animation_url
      const name = allMeta[index].name

      if (typeof animationUrl === 'string' && !animationUrl.includes('mp4')) {
        image = animationUrl.replace('ar://', 'https://arweave.net/')
      } else {
        image = allMeta[index].image.replace('ar://', 'https://arweave.net/')
      }
      return {
        image: image,
        name: name
      }
    }

    const filterResult = (filter: string, index: number) => {
      let hasFilter = false

      allMeta[index].attributes.forEach((attrKey, attrIndex) => {
        if (allMeta[index].attributes[attrIndex].value === filter) {
          hasFilter = true
        }
      })
      return hasFilter
    }

    useEffect(() => {
      const updateImageList = () => {
        setImages({})
        let updatedImageList: Images = {};
        if (filters.length === 0) {
          Object.keys(allMeta).forEach((key, index) => {
            const name = allMeta[index].name         
            updatedImageList[name] = addImageToList(index)
          })
        } else {

          Object.keys(allMeta).forEach((key, index) => {
            const filterResults = filters.every(filter => filterResult(filter, index))

            if (filterResults) {
              const name = allMeta[index].name            
              updatedImageList[name] = addImageToList(index)
            }
          })
        }
        setImages(updatedImageList)
        setPageCount(Math.ceil(Object.keys(updatedImageList).length / itemsPerPage))
      }
      updateImageList()
    },[filters])
    
    return (
        <div className="wrapper">
          <div className="page-container filter flex">
          <div className="left">
                <div className="title" style={{marginBottom: 20}}>8liens</div>
                <div className="">
                  <button onClick={clearFilters} className="btn-ghost">Clear Filters</button>
                </div>
                {
                    Object.keys(distribution).map((key, index) => {
                        return <div className="filter-wrapper" key={index}>
                            <div className="filter-section">
                                <img className="filter-icon" src={filterIcon} alt="filter icon" />
                                <div onClick={() => toggleHide(index)} className="filter-title">{key}</div>
                            </div>
                            {
                                !hiddenStatus[index] &&
                                Object.keys(dist[key].traits).map((distKey, distIndex) => {
                                    return <div className="filter-subsection" key={distIndex}>
                                        <label className="checkbox-container">
                                            <input type="checkbox" 
                                              checked={traitChecked[distKey] || false}
                                              onChange={() => handleCheckboxChange(distKey)} 
                                            />
                                            <span className="checkmark"></span>
                                            {distKey} 
                                            <span className="filter-number">{filters.length === 0 ? dist[key].traits[distKey].count : ""}</span>
                                        </label>
                                    </div>
                                })
                            }
                        </div>
                    })
                }
            </div>
            <div className="right">
                <div className="subtitle">
                  { Object.keys(images).length.toLocaleString() + " result(s) " }
                </div>
                <div className="nft-container">
                    {
                        Object.keys(images).slice(itemOffset, itemOffset + itemsPerPage).map((key, index) => {
                        return <div className="nft-section" key={index}>
                                <img
                                  className="nft-img" 
                                  src={images[key].image} 
                                  alt={key}
                                  onClick={() => navigate(`/8liens/${images[key].name.substring(8)}`)}
                                />
                                <div className="nft-info">
                                    <div className="">{images[key].name}</div>
                                </div>
                        </div>
                        })
                    }
                </div>
                <div id="container">
                  <ReactPaginate
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      nextLabel=">"
                      previousLabel="<"
                    />
                </div>
            </div>
          </div>
        </div>
    )
}

interface CheckedObj {
  [key: string]: boolean
}

interface Images {
  [key: string]: {
    image: string,
    name: string
  }
}

interface AllMetadata {
    attributes: {
        trait_type: string,
        value: string
    }[],
    image: string,
    name: string,
    animation_url?: string,
  }