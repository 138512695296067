import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './assets/scss/app.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MetaMaskProvider } from 'metamask-react';
import { FaqSection } from './components/faq-section';
import { Layout } from './components/layout';
import { AboutSection } from './components/about-section';
import { SpeciesSection } from './components/species-section';
import { NftPage } from './components/nft-page';
import { NotFound } from './components/not-found';
import { Your8liens } from './components/your-8liens';
import { Claim88C } from './components/88c-claim';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ToastContainer />
    <MetaMaskProvider>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path='/' element={<App />} />
            <Route path='/faq' element={<FaqSection />} />
            <Route path="/88c" element={<Claim88C />} />
            <Route path='/about' element={<AboutSection />} />
            <Route path='/species' element={<SpeciesSection /> } />
            <Route path='/8liens/:nftId' element={<NftPage /> } />
            <Route path='/your-8liens' element={<Your8liens />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Layout> 
      </BrowserRouter>
    </MetaMaskProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
