import { AppFooter } from "./app-footer"
import { AppHeader } from "./app-header"

export const Layout = (props: any) => {
    return (
        <>
            <AppHeader />
                {props.children}
            <AppFooter />
        </>
    )
}