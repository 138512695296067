import { useEffect, useState } from "react"
import { useMetaMask } from "metamask-react"
import { useNavigate } from "react-router-dom"

const apiKey = process.env.REACT_APP_ALCHEMY_ID
const contractAddress = "0x740c178e10662bbb050bde257bfa318defe3cabc"

export const Your8liens = () => {
  const [data, setData] = useState<Array<NftData>>([])
  const [loading, setLoading] = useState(true)
  const { status, account } = useMetaMask();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'connected') {
      fetch(`https://eth-mainnet.g.alchemy.com/nft/v2/${apiKey}/getNFTs?owner=${account}&contractAddresses[]=${contractAddress}`)
      .then(response => response.json())
      .then(response => {
        setData(response.ownedNfts)
        //console.log(response.ownedNfts)
        setLoading(false)
      })
      .catch(err => console.error(err));
    }
  }, [account, status]);
  
  return (
    <div className="wrapper">
      <div className="page-container">
        <div className="align-left">
          <div className="title">Your 8liens</div>
          <div className="nft-container-wrapper">
            { !loading &&
                data.map((nft, index) => {
                  return <div className="nft-container" key={index} onClick={() => navigate(`/8liens/${nft.title.substring(8)}`)}>
                    <img
                      className="nft-image" 
                      src={nft.media[0].gateway} 
                      alt={nft.title}
                    />
                    <div className="nft-title">{nft.title}</div>
                  </div>
                })
            }
          </div>
          { status !== 'connected' &&
            <div className="title highlight">Wallet Not Connected.</div>
          }
        </div>
      </div>
    </div>
  )
}

export interface NftData {
  id: {
    tokenId: string,
  }
  title: string,
  media: {
    gateway: string
  }[]
  metadata: {
    attributes: [{
      trait_type: string
      value: string
    }]
  }
}