export const FaqSection = () => {
  return (
    <div className="wrapper">
      <div className="page-container">
        <div className="title">FAQs</div>
        <div className="section">
          <div className="subtitle highlight">1. What are 8liens?</div>
          <div className="subtitle">8liens are creatures from the 8th dimension who will be spawned here to explore our world</div>
        </div>
        <div className="section">
          <div className="subtitle highlight">2. What chain is 8liens on?</div>
          <div className="subtitle">Ethereum Mainnet</div>
        </div>
        <div className="section">
          <div className="subtitle highlight">3. How much do 8liens cost?</div>
          <div className="subtitle">8liens are free, and there is no royalty on secondary</div>
        </div>
        <div className="section">
          <div className="subtitle highlight">4. What is your revenue model</div>
          <div className="subtitle">We are keeping 10% of 8liens for the 4 founders (like punks did)</div>
        </div>
        <div className="section">
          <div className="subtitle highlight">5. Is there a treasury?</div>
          <div className="subtitle">An additional 10% have been held back for development, some have been given to devs and
            advisors already and the remainder will be minted to a wallet for future development. Any
            future proceeds from this will all go back into the project. Any sales will be completely
            transparent and announced in advance.</div>
        </div>
        <div className="section">
          <div className="subtitle highlight">6. How were spawn list spots distributed?</div>
          <div className="subtitle">All spots were given out to the community through discord or Twitter, 1 spot per human (artists
            and devs and a few advisors received more than 1)</div>
        </div>
        <div className="section">
          <div className="subtitle highlight">7. Is there utility? A token? A Game? Metaverse?</div>
          <div className="subtitle">Lol- no. 8liens are NFT art, inspired by cryptopunks</div>
        </div>
        <div className="section">
          <div className="subtitle highlight">8. What can I do with my 8lien?</div>
          <div className="subtitle">All 8liens will be CC0, excluding the 1/1 pieces which will be determined individually by each artist</div>
        </div>
      </div>
    </div>
  )
}