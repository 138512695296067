export function AppFooter () {
    return (
        <div className="footer-wrapper">
          <div className="footer-section">
            <a 
              rel="noreferrer" 
              href="http://creativecommons.org/licenses/by/4.0/"
              target="_blank"
            >
              <img alt="Creative Commons License" 
                style={{borderWidth: '0'}}
                src="https://i.creativecommons.org/l/by/4.0/88x31.png"
              />
            </a>
          </div>
          <div className="footer-section">
            This work is licensed under a&nbsp;
            <a rel="noreferrer" 
              href="http://creativecommons.org/licenses/by/4.0/"
              target="_blank"
            >
              Creative Commons Attribution 4.0 International License.
            </a>
          </div>
        </div>
    )
}