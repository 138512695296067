export const truncateAddress = (address: string) => {
    return `${address.substring(0, 5)}...${address.substring(address.length - 5)}`
}

export const shortAddress = (address: string) => {
    return address.substring(0, 8)
}

export const formatLargeStat = (value: number) => {
    if (value > 999999999) {
        return (value / 1000000000).toFixed(2) + "B";
      } else if (value > 999999) {
        return (value / 1000000).toFixed(2) + "M";
      } else if (value > 999) {
        return (value / 1000).toFixed(2) + "K";
      } else {
        return value.toFixed(2);
      }
}