import { ethers } from "ethers";
import { useMetaMask } from "metamask-react";
import { useEffect, useMemo, useState } from "react"
import MinterABI from "../abis/minter.json";
import ERC1155ABI from "../abis/88c.json";
import AliensABI from "../abis/aliens.json";
import { NftData } from "./your-8liens";
import SnapshotSigs from "../sigs/SnapshotSig.json";
import BurnProofSigs from "../sigs/BurnProof.json";
import { ToastContainer, toast } from "react-toastify";

interface Snapshot {
    [address: string]: {
        [token: string]: string
    }
}

interface BurnProof {
    [token: string]: string
}

export const Claim88C = () => {

    const snapshot = SnapshotSigs as Snapshot;
    const burnProof = BurnProofSigs as BurnProof;

    const ALIENS_CONTRACT = '0x740c178e10662bBb050BDE257bFA318dEfE3cabC';
    const ERC1155_CONTRACT = '0x00000984fB0a64a94e4F5319DdFE052366D6b9B4';
    const MINTER_CONTRACT = '0x0C25b60FebE3870dB6c8bE71608BCa38d245A095';

    const [isWhitelisted, setIsWhitelisted] = useState<boolean>(false);
    const [ownedNfts, setOwnedNfts] = useState<Array<NftData>>([]);
    const [firemouthTokens, setFiremouthTokens] = useState<number[]>([]);
    const [selectedTokens, setSelectedTokens] = useState<Array<NftData>>([]);
    const [balance, setBalance] = useState<number>(0);
    const [isMinting, setIsMinting] = useState<boolean>(false);
    const [mintingComplete, setMintingComplete] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { status, account } = useMetaMask();

    const provider = useMemo(() => {
        if (status === "connected" && account !== null) {
            let provider;
            window.ethereum.enable().then(provider = new ethers.providers.Web3Provider(window.ethereum));
            const signer = provider.getSigner();
            return signer;
        }
        return null;
    }, [status, account]);

    const aliensContract = useMemo(() => {
        if (provider) {
            return new ethers.Contract(ALIENS_CONTRACT, AliensABI, provider);
        }
        return null;
    }, [provider]);

    const mintContract = useMemo(() => {
        if (provider) {
            return new ethers.Contract(MINTER_CONTRACT, MinterABI, provider);
        }
        return null;
    }, [provider]);

    const erc1155Contract = useMemo(() => {
        if (provider) {
            return new ethers.Contract(ERC1155_CONTRACT, ERC1155ABI, provider);
        }
        return null;
    }, [provider]);

    const canClaim = useMemo(() => {
        if (balance >= 0 && firemouthTokens.length >= 0) {
            return !(balance === firemouthTokens.length);
        }
        return false;
    }, [balance, firemouthTokens])

    const createClaim = () => {
        const claims: any[] = [];
        if (account) {
            selectedTokens.forEach((token, index) => {
                const newClaim = [
                    firemouthTokens[index],
                    parseInt(token.id.tokenId, 16),
                    snapshot[account][firemouthTokens[index].toString()],
                    Object.keys(burnProof).includes(parseInt(token.id.tokenId, 16).toString()) ? (
                        burnProof[parseInt(token.id.tokenId, 16).toString()]
                    ) : ( [] )
                ];
                claims.push(newClaim);
            })
        }
        return claims;
    }
    
    const onSubmit = async () => {
        if (aliensContract && mintContract) {
            setIsMinting(true);
            const isApproved = await aliensContract.isApprovedForAll(account, MINTER_CONTRACT);
            if (!isApproved) {
                await toast.promise(
                    aliensContract.setApprovalForAll(MINTER_CONTRACT, true),
                    {
                        success: 'Approval Successful!',
                        pending: 'Awaiting Token Approval...',
                        error: 'Approval Failed!'
                    },
                    {
                        theme: 'dark',
                        autoClose: 5000,
                        closeOnClick: true,
                        position: 'top-center'
                    }
                ).then(async () => {
                    const claims = createClaim();
                    console.log('Claims:');
                    console.log(claims);
                    await toast.promise(
                        mintContract?.claim88C(1, claims),
                        {
                            success: '88C Claim Successful!',
                            pending: 'Burning 8liens for 88C Tokens...',
                            error: 'Claim Failed. Your tokens have not been burned.'
                        },
                        {
                            theme: 'dark',
                            autoClose: 5000,
                            closeOnClick: true,
                            position: 'top-center'
                        }
                    )
                    .then((txn) => {
                        console.log(txn);
                        setIsMinting(false);
                        setMintingComplete(true);
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsMinting(false);
                    });
                })
            } else {
                const claims = createClaim();
                console.log('Claims:');
                console.log(claims);
                await toast.promise(
                    mintContract?.claim88C(1, claims),
                    {
                        success: '88C Claim Successful!',
                        pending: 'Burning 8liens for 88C Tokens...',
                        error: 'Claim Failed. Your tokens have not been burned.'
                    },
                    {
                        theme: 'dark',
                        autoClose: 5000,
                        closeOnClick: true,
                        position: 'top-center'
                    }
                )
                .then((txn) => {
                    console.log(txn);
                    setIsMinting(false);
                    setMintingComplete(true);
                })
                .catch((err) => {
                    console.log(err);
                    setIsMinting(false);
                })
            }
        }
    }

    useEffect(() => {
        async function getBalance(address: string, contract: ethers.Contract) {
            const bal = await contract.balanceOf(address, 1);
            if (bal) {
                setBalance(bal.toNumber());
            }
        }
        if (account && erc1155Contract) {
            getBalance(account, erc1155Contract);
        }
    }, [erc1155Contract, account])

    useEffect(() => {
        let aggregatedNfts: NftData[] = [];  // Temporary storage for the fetched NFTs
    
        const fetchAllNFTs = async (pageKey = null) => {
            setIsLoading(true);
            try {
                let url = `https://eth-mainnet.g.alchemy.com/nft/v2/Nmy2naEkUaENmxoqxEZTip6CzWS3FNek/getNFTs?owner=${account}&contractAddresses[]=0x740c178e10662bBb050BDE257bFA318dEfE3cabC`;
                
                // Append pageKey to the URL if it's available
                if (pageKey) {
                    url += `&pageKey=${pageKey}`;
                }
    
                const response = await fetch(url);
                const data = await response.json();
    
                // Concatenate data to the temporary storage
                aggregatedNfts = [...aggregatedNfts, ...data.ownedNfts];
    
                // If a pageKey is returned, fetch the next page
                if (data.pageKey) {
                    await fetchAllNFTs(data.pageKey);
                } else {
                    // When no more pages are left, update the state
                    setOwnedNfts(aggregatedNfts);
                }
            } catch (err) {
                console.error(err);
            }
            setIsLoading(false);
        };
    
        if (status === 'connected' && isWhitelisted) {
            fetchAllNFTs();
        }
    
        // Only set loading to false once all NFTs are fetched
        return () => setIsLoading(false);
    }, [account, status, isWhitelisted]);
    
    

    useEffect(() => {
        if (status === "connected") {
            if (Object.keys(snapshot).includes(account.toLowerCase()) || Object.keys(snapshot).includes(account)) {
                setIsWhitelisted(true);
                if (balance > 0) {
                    const fmTokens = Object.keys(snapshot[account.toLowerCase()]).slice(balance);
                    console.log(fmTokens);
                    setFiremouthTokens(fmTokens.map((t) => parseInt(t)));
                } else {
                    console.log(
                        Object.keys(snapshot[account.toLowerCase()]).map((t) => parseInt(t))
                    );
                    setFiremouthTokens(Object.keys(snapshot[account.toLowerCase()]).map((t) => parseInt(t)))
                }   
            }
        }
    }, [status, account, snapshot, balance]);

    return (
        <div className="wrapper">
            <div className="page-container">
                <div className="align-left">
                    {isLoading &&
                        <h1 className="title">
                            Checking Snapshot and Loading Tokens...
                        </h1>
                    }
                    {!isLoading &&
                        <>
                            { mintingComplete &&
                                <h1 className="title">
                                    88C Minting Complete! Check your wallet for tokens!
                                </h1>
                            }
                            { isMinting &&
                                <>
                                    <h1 className="title">
                                        Minting Your New 88C Tokens...
                                    </h1>
                                </>
                            }
                            { !isMinting &&
                                <>
                                    <h1 className="title">
                                        Claim Your 88C Tokens
                                    </h1>
                                    {
                                        isWhitelisted &&
                                        <>
                                            { canClaim ? (
                                                <>
                                                    <p className="subtitle">
                                                        For every Fire! Mouth 8lien held at the snapshot, you may burn 1 8lien for 1 88C token. Flame Head and Fire Body 8liens are burn-proof, you will still get the 88C token, however you keep your 8lien.
                                                    </p>
                                                    <br/><br/><br/>
                                                    <div className="row">
                                                        <p className="subtitle">
                                                            Select up to {firemouthTokens.length} 8liens to burn<br/>Total Selected: {selectedTokens.length} 8liens
                                                        </p>
                                                        <button className="btn-ghost mintButton" onClick={onSubmit}>
                                                            Burn
                                                        </button>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <p className="subtitle">
                                                        You've claimed all your available 88C tokens! Balance: {balance}
                                                    </p>
                                                </>
                                            )}
                                        </>
                                    }
                                    { !isWhitelisted &&
                                        <p>
                                            You are not whitelisted! You can buy 88C tokens on secondary marketplaces!
                                        </p>
                                    }
                                    <div className="row">
                                        <h1 className="subtitle">
                                            Available Tokens:
                                        </h1>
                                        <h1 className="subtitle align-right">
                                            Selected To Burn:
                                        </h1>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        
                                        <div className="nft-88c-container-wrapper">
                                            { isWhitelisted && canClaim &&
                                                <>
                                                    { ownedNfts.length > 0 &&
                                                        ownedNfts.map((nft, index) => {
                                                        return (
                                                            <>
                                                                {
                                                                    !selectedTokens.includes(nft) &&
                                                                    <div className="nft-container" key={index} onClick={() => {
                                                                        if (selectedTokens.includes(nft)) {
                                                                            const newTokens = selectedTokens.filter(item => item !== nft);
                                                                            setSelectedTokens(newTokens);
                                                                        } else {
                                                                            if (selectedTokens.length < firemouthTokens.length) {
                                                                                const newTokens = [nft, ...selectedTokens];
                                                                                setSelectedTokens(newTokens);
                                                                            }
                                                                        }
                                                                    }}>
                                                                        <img
                                                                        className={`nft-image ${selectedTokens.includes(nft) ? `selected` : ''}`} 
                                                                        src={nft.media[0].gateway} 
                                                                        alt={nft.title}
                                                                        />
                                                                        <div className="nft-title">{nft.title}</div>
                                                                    </div>
                                                                }
                                                            </>
                                                        )})
                                                    }
                                                </>
                                                
                                            }
                                        </div>
                                        <div className="nft-88c-container-wrapper">
                                            { isWhitelisted && canClaim &&
                                                <>
                                                    { selectedTokens.length > 0 &&
                                                        selectedTokens.map((nft, index) => {
                                                        return (<div className="nft-container" key={index} onClick={() => {
                                                            if (selectedTokens.includes(nft)) {
                                                                const newTokens = selectedTokens.filter(item => item !== nft);
                                                                setSelectedTokens(newTokens);
                                                            } else {
                                                                if (selectedTokens.length < firemouthTokens.length) {
                                                                    const newTokens = [nft, ...selectedTokens];
                                                                    setSelectedTokens(newTokens);
                                                                }
                                                            }
                                                        }}>
                                                            <img
                                                                className={`nft-image`} 
                                                                src={nft.media[0].gateway} 
                                                                alt={nft.title}
                                                            />
                                                            <div className="nft-title">{nft.title}</div>
                                                        </div>
                                                        )})
                                                    }
                                                </>
                                                
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

interface Claim {
    snapshotId: ethers.BigNumberish
    burnId: ethers.BigNumberish
    proofOfSnapshot: string
    proofToSaveBurnId: string
}

interface Signature {
    sig: string
    isBurn: boolean
    token: number
}
