import { useMetaMask } from "metamask-react";
import { useEffect, useState } from "react";
import { truncateAddress } from "../utility/formatting";
import { Link, useLocation, useNavigate } from "react-router-dom"
import twitterLogo from "../assets/img/twitter-6.svg"
import logo from "../assets/img/8Logo.png"
import discordLogo from "../assets/img/discord.png"
import openseaLogo from "../assets/img/opensea.svg"
import menuIcon from "../assets/img/menu-white.svg"
import { toast } from "react-toastify";
import useWindowDimensions from "./window-dimensions";

const mobileMenu = [
  { title: "Home", link: "/" },
  { title: "Species", link: '/species'},
  { title: "FAQ", link: '/faq'},
  { title: "About", link: '/about'},
  { title: "Claim 88C", link: "/88c"},
]

export function AppHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const { status, connect, account } = useMetaMask();
  const [walletButtonText, setWalletButtonText] = useState("Connect Wallet")
  const [isMobile, setIsMobile] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    const connectButtonHandler = () => {
      if (status === 'connected') {
        navigate('/your-8liens')
      } else if (status === "notConnected") {
          connect()
      } else if (status === "unavailable") {
          toast.error("Please install Metamask.")
      }
    }

    const toggleMobileMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen)
    }

    useEffect(() => {
      if (width <= 740) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    },[width])

    useEffect(() => {
      setIsMobileMenuOpen(false)
    }, [location])

    useEffect(() => {
        const getWalletButtonText = () => {
            if (status === "connected") {
                setWalletButtonText(truncateAddress(account))
            } else {
                setWalletButtonText("Connect Wallet")
            }
        }
        getWalletButtonText()
    },[status, account])

    return (
      <nav>
      <div className="nav-wrapper">
        <div className="nav-section">
          <img id="logo" src={logo} alt="8liens Logo"/>
          <div className="title">8LIENS</div>
        </div>
        { !isMobile &&
          <div className="nav-section">
            <div>
              <Link to="/" className="link-item">Home</Link>
            </div>
            <div>
              <Link to="/species" className="link-item">Species</Link>
            </div>
            <div>
              <Link to="/faq" className="link-item">FAQ</Link>
            </div>
            <div>
              <Link to="/about" className="link-item">About</Link>
            </div>
            <div>
              <Link to="/88c" className="link-item">Claim 88C</Link>
            </div>
            <div style={{marginLeft: 10}}>
              <button className="btn-ghost" onClick={connectButtonHandler}>{walletButtonText}</button>
            </div>
          </div>
        }
        { !isMobile &&
          <div className="nav-section">
            <a href="https://twitter.com/8liensNFT" target="_blank" rel="noreferrer">
              <img id="twitter-icon" src={twitterLogo} alt="Twitter Logo" />
            </a>
{/*             <a href="https://discord.gg/8liens" target="_blank" rel="noreferrer">
              <img id="discord-icon" src={discordLogo} alt="Discord Logo" />
            </a> */}
            <a href="https://opensea.io/collection/8liens" target="_blank" rel="noreferrer">
              <img id="discord-icon" src={openseaLogo} alt="OpenSea Logo" />
            </a>
          </div>
        }
        {
          isMobile &&
          <div 
            className="nav-section"
          >
            <img 
              src={menuIcon}
              className={`icon ${ isMobileMenuOpen ? 'icon-open' : '' }`}
              alt='Menu Icon'
              onClick={toggleMobileMenu}
            />
          </div>
        }
        </div>
        {
          isMobile &&
            <ul className={`mobile-menu ${ isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
              <div className="nav-section">
                <div className="title" style={{marginBottom: 20}}>8liens</div>
              </div>
              <div style={{marginBottom: 20}}>
                <button className="btn-ghost" onClick={connectButtonHandler}>{walletButtonText}</button>
              </div>
              {
                mobileMenu.map((item, index) => {
                  return <li key={index}>
                    <Link to={item.link} className="mobile-link-item">{item.title}</Link>
                  </li>
                })
              }
              <div className="nav-section" style={{marginTop: 20}}>
                <a href="https://twitter.com/8liensNFT" target="_blank" rel="noreferrer">
                  <img id="twitter-icon" src={twitterLogo} alt="Twitter Logo" style={{margin: '0 10px 3px 0'}} />
                </a>
                <a href="https://discord.gg/8liens" target="_blank" rel="noreferrer">
                  <img id="discord-icon" src={discordLogo} alt="Discord Logo" style={{marginRight: 22}} />
                </a>
                <a href="https://opensea.io/collection/8liens" target="_blank" rel="noreferrer">
                  <img id="discord-icon" src={openseaLogo} alt="OpenSea Logo" style={{margin: '0 10px 1px 0'}} />
                </a>
              </div>
            </ul>
        }
        
        { isMobile && isMobileMenuOpen &&
          <div className="mobile-menu-backdrop" onClick={() => setIsMobileMenuOpen(false)}></div>
        }

      </nav>
    )
}
