import noone from "../assets/img/noone.jpg"
import bharat from "../assets/img/bharat.png"
import dievardump from "../assets/img/dievardump.png"
import scalynelson from "../assets/img/scalynelson.png"
import debussy from "../assets/img/deb8lien.png"
import unstableKido from "../assets/img/unst8blien.png"
import punkVandelay from "../assets/img/van8lien.png"

export const AboutSection = () => {
  return (
    <div className="wrapper font-18">
      <div className="page-container">
        <div className="title">Team</div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={unstableKido} className="img-team" alt="Unstable Kido"/>
            </div>
            <div className="text-section">
              <span className="highlight bold">Unstable Kido</span> - Founder/Primary Artist - is an artist from South Korea. 
              He has been creating art since he was a child and has been active as an NFT artist 
              since early 2021. His first collection was primarily focused on 1/1 art and has been 
              collected by many prominent collectors.
          </div>
          </div>
        </div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={scalynelson} className="img-team" alt="Scalynelson"/>
            </div>
            <div className="text-section">
              <span className="highlight bold">Scalynelson</span> - Founder - has been in the NFT space since 
              early 2018 as an avid collector, educator, and NFT enthusiast. He was an early 
              collector of Cryptopunks, Beeple, Hackatao and X-copy.  He also was one of the first 
              members of the closed Beta for NBA topshot, and has helped advised artists and 
              collectors along the way.
          </div>
          </div>
        </div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={debussy} className="img-team" alt="Debussy"/>
            </div>
            <div className="text-section">
              <span className="highlight bold">Debussy</span> - Founder - entered the crypto space in 2017 and 
              got into NFTs in August 2020. He's known to notice trends and narrative in the space 
              and run with them. Some notable runs were his Beeple, Cryptopunks and Rarepepe trades. 
              He wants to contribute something meaningful to the NFT space and create value for the 
              entire ecosystem.
          </div>
          </div>
        </div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={punkVandelay} className="img-team" alt="Punk Vandelay"/>
            </div>
            <div className="text-section">
              <span className="highlight bold">Punk Vandelay</span> - Founder - has been involved in the crypto space since 2017 as an investor and began collecting NFTs in early 2020. Some highlights of his career as a collector include purchasing a CryptoPunk as his first NFT, minting a Fidenza and diamond handing countless other jpegs. He has witnessed the birth of many NFT trends and with this experience has advised artists and other collectors through many cycles.
          </div>
          </div>
        </div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={dievardump} className="img-team" alt="Dievardump"/>
            </div>
            <div className="text-section">
              <span className="highlight bold">Dievardump</span> - Developer - is a smart contract 
              apprentice-wizard, exploring the edge of on-chain shenanigans with projects like 
              [sol]Seedlings and giving the power to artists with projects like BeyondNFT, 
              Wallkanda and NiftyForge. He has been doing collabs with artists like 
              GenuineHumanArt, Nahiko, A.L. Crego or Fabin, trying to bring cool on-chain 
              mechanism to great artists work.
          </div>
          </div>
        </div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={noone} className="img-team" alt="NooNe"/>
            </div>
            <div className="text-section">
              <span className="highlight bold">NooNe</span> - Advisor - joined the crypto movement in 2015 and 
              started collecting NFTs in December 2020. He is a great believer in decentralisation 
              and the role of NFTs to secure fair uncensored ownership of digital objects. He is 
              obsessed with Cryptopunks, RarePepes and XCOPY. As part of the team at 6529Capital, 
              he spends most of his time seizing the memes of production.
          </div>
          </div>
        </div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={bharat} className="img-team" alt="Bharat Krymo"/>
            </div>
            <div className="text-section">
              <span className="highlight bold">Bharat Krymo</span> - Advisor - has been in crypto since 2013, 
              but dove in hard in 2016.  Got into DeFi and NFTs in 2018.  Early Xcopy, Coldie and 
              Hackatao HODLer. Top 5 collector on SuperRare.  He is a GP in 6529 Capital.
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}