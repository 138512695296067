import allMetadata from "..//assets/metadata/all.json"

export const importAllImages = (r: __WebpackModuleApi.RequireContext) => {
    let images: ImportImages = {}
    r.keys().forEach((item, index) => {
        images[item.replace('./', '')] = r(item)
    })
    return images
}

export const importAll = (r: __WebpackModuleApi.RequireContext) => {
    let images: ImportObj = {}
    r.keys().forEach((item, index) => {
        images[item.replace('./', '')] = {
            image: r(item),
            name: findImageName(item.replace('./', ''))
        }
    })
    return images
}

const findImageName = (name: string) => {
    for (let i=0; i < allMetadata.length; i++) {
        if (allMetadata[i].image.includes(name)) {
            return allMetadata[i].name
        }
    }
    return "8lien"
}

interface ImportObj {
    [index: string]: {
        image: string,
        name: string
    }
}

interface ImportImages {
    [index: string]: string
}