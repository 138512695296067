import ethereal from "../assets/img/species/Ethereal_1.png"
import eightlien from "../assets/img/species/8lien_9783.png"
import tetrabot from "../assets/img/species/Tetrabot_66.png"
import triton from "../assets/img/species/Triton_8.png"
import xenomorph from "../assets/img/species/Xenomorph_23.png"
import unique from "../assets/img/species/1of1_PindarVanArman.gif"

export const SpeciesSection = () => {
  return (
    <div className="wrapper font-18">
      <div className="page-container">
        <div className="title">Species</div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={ethereal} className="img-team" alt="Ethereal Example"/>
            </div>
            <div className="">
              <span className="highlight bold">Ethereals</span> are the first eight 
              8liens that ever existed. Their age is unknown to us as they are the 
              only known beings with the ability to fold space and time to travel 
              through dimensions. How they developed this ability is unknown- 
              perhaps they evolved or were created?
          </div>
          </div>
        </div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={eightlien} className="img-team" alt="8lien Example"/>
            </div>
            <div className="">
              <span className="highlight bold">8liens</span> have been spawned by the Ethereals 
              for travel to other dimensions. Their main purpose is exploration and discovery. 
              There are six different sub-species and they do not have genders. They also
              possess different !vibes and traits.
          </div>
          </div>
        </div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={tetrabot} className="img-team" alt="Tetrabot Example"/>
            </div>
            <div className="">
              <span className="highlight bold">Tetrabots</span>, or BOTs, created advanced AIs 
              which ultimately led them to morph themselves with machines in order to maximize 
              their full potential. The mysterious screen on top of their heads is their
              way of communicating with the outside world using imagery rather than speaking- 
              is this to avoid divulging their secrets to other 8liens? 8lien civilization has 
              increased logarithmically thanks to the BOTs, however no more than eighty-eight can
              ever exist for fear they may one day threaten even the Ethereals.
          </div>
          </div>
        </div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={xenomorph} className="img-team" alt="Xenomorph Example"/>
            </div>
            <div className="">
              <span className="highlight bold">Xenomorphs</span> were genetically engineered by
                someone to be perfect killing machines- used to exterminate whatever civilization 
                existed on a planet to prepare it for colonization. When they were sent to the 8th 
                dimension they encountered the Ethereals and were subjugated. To our knowledge no 
                other being has ever tamed a Xenomorph before. How did they enter the 8th dimension?
          </div>
          </div>
        </div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={triton} className="img-team" alt="Triton Example"/>
            </div>
            <div className="">
              <span className="highlight bold">Tritons</span> are the explorers of all eight 
              dimensions. They often are sent together with their arch-rivals,
              the Xenomorphs, to new galaxies on behalf of the
              Ethereals. Philosophers at heart, they are skilled in
              diplomacy and negotiations, and find the brute
              violence of the Xenomorphs repulsive. Their 
              relationship with the Xenomorphs is complicated as
              they also rely on their protection. The 8liens look up
              to the Tritons, known for their morals and emphasis
              on education.
          </div>
          </div>
        </div>
        <div className="section">
          <div className="flex vertical-center">
            <div className="img-container">
              <img src={unique} className="img-team" alt="1/1 Example"/>
            </div>
            <div className="">
              <span className="highlight bold">1/1</span>: The 1/1 8liens, commissioned by 
              guest artists, are the grails of the collection. But don't tell the
              Ethereals that. 
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}