import { HeroSection } from './components/hero-section';
import { NftSectionLg } from './components/nft-section-lg';
//import { StatsSection } from './components/stats-section';
import { FilterSection } from './components/filter-section';

function App() {

  return (
      <div>
        <HeroSection />
        <NftSectionLg />
        
        <FilterSection />
      </div>
  );
}

export default App;
