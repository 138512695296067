import { importAllImages } from "../utility/utilities"

export function NftSectionLg () {
    const images = importAllImages(require.context('../assets/img/nft-section-lg', false, /\.(png|jpe?g|svg)$/))
    
    return (
        <div className="nft-section-lg-wrapper">
            {
               Object.keys(images).map((key, index) => {
                   return <img className="img-nft" src={images[key]} alt={key} key={key}/>
               })
            }
        </div>
    )
}