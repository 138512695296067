//import { ethers } from "ethers"
import { useMetaMask } from "metamask-react";
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
//import nftAbi from "../assets/metadata/8liens.json"
//import spawnAbi from "../assets/metadata/8liensSpawner.json"
//import publicMintAbi from "../assets/metadata/publicMintAbi.json"
import featuredImg from '../assets/img/featured.jpg'
import { useNavigate } from "react-router-dom";

export function HeroSection () {
    const { status, connect, account, chainId, ethereum } = useMetaMask();
    const navigate = useNavigate()
    
    //Mainnet
    //const spawnAddress = "0x1A0a589f19544cCE54EC381D30180A24300F41CF"
    //const publicMintAddress = "0x6f89021bbee7b6992fc2bff0970a2a660e2e7106"
    //const nftAddress = "0x740c178e10662bbb050bde257bfa318defe3cabc"

    //Rinkeby Testnet
    //const spawnAddress = "0x6a875888Fb937695742abcDae8169c4Df0dDa9D9"
    //const whitelist: Whitelist = spawnList

    //const [loading, setLoading] = useState(false)
    //const [currentTier, setCurrentTier] = useState(0)
    //const [hasPublicMinted, setHasPublicMinted] = useState(false)
    const [message, setMessage] = useState("All 8liens have been spawned.")
    //const [contract, setContract] = useState<ethers.Contract>()
    //const [publicContract, setPublicContract] = useState<ethers.Contract>()
    //const [isAllowed, setIsAllowed] = useState(false)
    //const [numMinted, setNumMinted] = useState(0)
    //const [numAllowed, setNumAllowed] = useState(0)
    //const [signature, setSignature] = useState("")
    //const [whitelist, setWhitelist] = useState<Whitelist>({})
    //const [address, setAddress] = useState("")
    //const [isHuman, setIsHuman] = useState(false)
    //const [isPublicMint, setIsPublicMint] = useState(false)
    //const [nonce, setNonce] = useState(0)

    /*const calculateCheck = (address: string | null) => {
        if (typeof address === "string") {
            return ethers.BigNumber.from(address).div(0x1337).mod(0x80085).mul(0x69420);
        }
    }*/

    /*useEffect(() => {
        fetch("https://dievardump.com/uploads/spawnlist.json")
        .then((response) => response.json())
        .then((data) => {
            //console.log(data)
            setWhitelist(data)
        })
    },[])*/
    
    /*const getData = useCallback(async () => {
        //console.log("Getting Data...")
        if (status === "connected") {
            const provider = new ethers.providers.Web3Provider(ethereum)
            await provider.send("eth_requestAccounts", [])
            const signer = provider.getSigner()
            
            const nftContract = new ethers.Contract(nftAddress, nftAbi, signer)
            if (await nftContract.minter() === "0x6f89021BBeE7b6992FC2BFF0970a2a660E2e7106") {
                setIsPublicMint(true)
            }
            const spawnContract = new ethers.Contract(spawnAddress, spawnAbi, signer)
            setContract(spawnContract)
            const publicMintContract = new ethers.Contract(publicMintAddress, publicMintAbi, signer)
            setPublicContract(publicMintContract)
            setCurrentTier( Number( await spawnContract.currentTier() ))
            setHasPublicMinted(await publicMintContract.hasPublicMinted(account))
            setNumMinted(Number(await spawnContract.numberMinted(account)))
            const address = ethers.utils.getAddress(account)
            setAddress(address)
            const allowed = Object.keys(whitelist).includes(account)
            setIsAllowed(allowed)
            if (typeof account === "string" && isAllowed) {
                setNumAllowed(whitelist[account].amount)
                setSignature(whitelist[account].signature)
            }
        }
    }, [account, ethereum, isAllowed, status, whitelist])
   
    useEffect(() => {
        getData()
    },[getData, loading])*/

    /*useEffect (() => {  
        if (isPublicMint) {
            if (hasPublicMinted === true) {
                setMessage("You have already spawned your 8lien.")
            } else {
                setMessage("You are allowed one public spawn.")
            }
        } else if (currentTier === 1) {
            if (isAllowed) {
                setMessage(`You have spawned (${numMinted} of ${numAllowed}) 8liens.`)
            } else {
                setMessage("Apologies, this address is not on the spawn list.")
            }
        } else if (status !== "connected") {
            setMessage("Please connect your wallet.")
        } else {
            setMessage("Spawning not currently allowed.")
        }
    },[currentTier, hasPublicMinted, isAllowed, numAllowed, numMinted, status, isPublicMint])*/

    /*const spawn8lien = async () => {
        setLoading(true)
        try {
            if (contract !== undefined) {
                if (isPublicMint && !hasPublicMinted && isHuman) {
                    if (typeof publicContract !== "undefined") {
                        await publicContract.spawn(calculateCheck(account))
                    }
                    toast.success("8lien spawned successfully.")
                } else if (isPublicMint && hasPublicMinted) {
                    toast.error("You have already spawned your 8lien.")
                } else if (currentTier === 1 && isAllowed && (numMinted < numAllowed)) {
                    if (numMinted === 0) {
                        await contract.mint(numAllowed, signature)
                    } else {
                        const allowanceLeft = numAllowed - numMinted
                        await contract.mintTo(address, allowanceLeft, numAllowed, signature)
                    }
                    //console.log("numAllowed:", numAllowed)
                    //console.log("signature:", signature)
                    toast.success("8lien(s) spawned successfully.")
                } else if (currentTier === 1 && isAllowed && (numMinted >= numAllowed)) {
                    toast.error("You have already minted the maximum amount.")
                } else if (currentTier === 1 && !isAllowed) {
                    toast.error("Sorry, this address has not been whitelisted.")
                }
            }
            
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong.")
        }
        getData()
        setIsHuman(false)
        setLoading(false)
    }*/

    /*const noButtonHandler = () => {
        setIsHuman(true)
    }

    const yesButtonHandler = () => {
        setIsHuman(false)
    }*/

    return (
        <div className="hero-wrapper flex">
            <div className="hero-left">
                <div className="title">
                    The Birth of 8liens
                </div>
                <div className="text">
                    <p>
                        8liens have been spawned by the Ethereals for travel to other dimensions. 
                        Their main purpose is exploration and discovery. There are six different 
                        sub-species and they do not have genders. They also possess different 
                        !vibes and traits.
                    </p>
                </div>
                {
                    /*!isHuman && isPublicMint &&
                    <div className="" style={{marginBottom: 12}}>
                        <div className="text" style={{marginBottom: 8}}>Are you a bot?</div>
                        <button onClick={yesButtonHandler} className="" style={{marginRight: 8}}>Yes</button>
                        <button onClick={noButtonHandler} className="">No</button>
                    </div>*/
                }
                <div className="highlight text" style={{marginTop: 12}}>{message}</div>
                <div className="text" style={{marginTop: 12}}>
                    <a href="https://opensea.io/collection/8liens"
                        className="text"
                        target="_blank" 
                        rel="noreferrer">
                        See the collection on OpenSea.
                    </a>
                </div>
                { status === 'connected' &&
                  <div 
                    className="text highlight"
                    style={{ marginTop: 12, cursor: 'pointer'}}
                    onClick={() => navigate('/your-8liens')}
                  >
                    View your 8liens
                  </div>
                }
            </div>
            <div className="hero-right">
                <img id="featured-img" src={featuredImg} alt="featured 8lien"
                  onClick={() => navigate('/8liens/4185')}
                />
            </div>
        </div>
    )
}

interface Whitelist {
    [key: string]: {
        amount: number,
        signature: string
    }
}