import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import distribution from "../assets/metadata/distribution.json"

const dist: Distribution = distribution

export const NftPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [speciesData, setSpeciesData] = useState({
    value: "",
    total: 0
  })
  const [traitsData, setTraitsData] = useState<TraitsData[]>([])
  const [data, setData] = useState<ApiData>({
    name: "8liens #",
    url: "",
    traits: []
  });
  
  let { nftId } = useParams();

  useEffect(() => {
    fetch(`https://paulrennick.com/8liens/${nftId}`)
      .then(response => response.json())
      .then(response => {
        setData(response)
        setIsLoading(false)
        //console.log(response)
      })
      .catch(err => console.error(err));
  }, [nftId]);

  useEffect(() => {
    let traitData: TraitsData[] = []
    data.traits.forEach(trait => {
      if (trait.trait_type === 'Species') {
        setSpeciesData({
          value: trait.value,
          total: dist.Species.traits[trait.value].count
        })
      } else {
        traitData.push({
          trait_type: trait.trait_type,
          value: trait.value,
          total: dist[trait.trait_type].traits[trait.value].count
        })
      }
    })
    setTraitsData(traitData)
  },[data])

  return (
    <div className="wrapper">
      <div className="page-container">
      { Number.isInteger(Number(nftId)) && Number(nftId) >= 0 && Number(nftId) <= 10000 &&
        <>
          <img 
            className="img-container-nftpage" 
            src={data.animation_url || data.url} 
            alt={data.name}
          />
          <div className="title">{data.name}</div>
          <div className="section">
            <div className="text">{`One of ${speciesData.total.toLocaleString()} with species:`} 
              <span className="highlight">{` ${speciesData.value}`}</span>.
            </div>
          </div>
          <div className="section">
            <div className="title">Traits</div>
            {
              traitsData.map((trait, index) => {
                return <div className="text" key={index}>
                    <div className="trait-section">
                      <span className="highlight">{trait.value}</span> - {trait.total.toLocaleString()} 8liens have this {trait.trait_type}.
                    </div>
                </div>
              })
            }
          </div>
        </>
      }
      { (!Number.isInteger(Number(nftId)) || Number(nftId) < 0 || Number(nftId) > 10000) &&
        <div className="title">8lien not found.</div>
      }
      </div>
    </div>
  );
};

interface ApiData {
  name: string,
  animation_url?: string,
  url: string,
  traits: {
    trait_type: string,
    value: string,
  }[]
}

interface OpenSeaAsset {
  animation_url?: string
  image_preview_url: string
  name: string,
  owner: {
    address: string
  }
  traits: {
    trait_type: string,
    value: string,
  }[]
}

interface TraitsData {
  trait_type: string,
  value: string,
  total: number 
}

interface Distribution {
  [type: string]: {
    count: number,
    distribution: number,
    traits: {
      [traitName: string]: {
        count: number,
        distribution: number
      }
    }
  }
}